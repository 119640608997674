<script>
import Layout from "../../layouts/main";
import appConfig from "@/app.config";
import ProductService from '@/services/product'
import Multiselect from 'vue-multiselect'
import draggable from 'vuedraggable';

export default {
  page: {
    title: "Fotos de Produtos",
    meta: [{ name: "description", content: appConfig.description }]
  },
  components: { Layout, Multiselect, draggable },
  data() {
    return {
      title: "Fotos de Produtos",
      products: [],
      product: '',
      isLoading: false,
      images: [],
      indexToRemove: null,
    };
  },
  methods: {
    handleFilesUpload(){      
      const files = this.$refs.file.files;

      for( var i = 0; i < files.length; i++ ) {
        const index = this.images.length;

        this.images.push('temp');

        let file = files[i];
        
        let formData = new FormData();
        formData.append('file', file);

        ProductService.addProductImage(formData, this.product.code).then(res => {
          this.images.splice(index, 1);
          this.images.push(res.data.image)

          this.$forceUpdate();

          this.$toast.open('Imagem adicionada com sucesso');
        }).catch(() => {
          this.images.splice(index, 1);
          this.$forceUpdate();

          this.$toast.error('Falha no envio da imagem');
        })
      }      
    },
    removeImage() {
      this.$bvModal.hide('modal-remove-image');

      this.images.splice(this.imageToRemove, 1);

      ProductService.updateImages(this.product.code, this.images).then(() => {
        this.$toast.open('Imagem removida com sucesso');
      }).catch(() => {
        this.$toast.error('Ocorreu um erro ao remover a imagem');
      })
    },
    findProduct(term) {
      this.isLoading = true;

      ProductService.getUniqueProducts(term).then(res => {
        this.isLoading = false;

        this.products = res.data;
      }).catch(() => {
        this.isLoading = false;
      })
    },
    selectProduct(item) {
      this.images = item.images || [];
      this.product = item;
    },
    onDragImage() {
      ProductService.updateImages(this.product.code, this.images).then(() => {})
    }
  },
};
</script>

<template>
  <Layout>
    <div class="row">
      <div class="col-12">
        <div class="page-title-box d-flex align-items-center justify-content-between">
          <h4 class="mb-0 font-size-18">{{title}}</h4>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-xl-12">
        <div class="card">
          <div class="card-body">
            <div class="row">
              <div class="col-sm-6">
                <div class="form-group">
                  <label class="control-label">Produto</label>
                  <multiselect
                    v-model="product"
                    :options="products"
                    :close-on-select="true"
                    :clear-on-select="true"
                    :loading="isLoading"
                    @search-change="findProduct"
                    @select="selectProduct"
                    placeholder="Selecione o produto por nome ou código"
                    label="fullDescription"
                    track-by="code"
                    selectLabel="Enter para selecionar"
                    selectedLabel="Selecionado"
                    deselectLabel="Enter para remover"
                  ></multiselect>
                </div>
              </div>
            </div>

            <!-- <h4 class="card-title mb-3">Imagens do Produto</h4> -->
            <!-- <div class="images">  -->
            <draggable class="images" :list="images" @end="onDragImage">           
              <div v-for="(image, index) in images" :key="index" class="image" @mouseover="indexToRemove = index" @mouseleave="indexToRemove = null">
                <div v-if="image !== 'temp' ? true : false" class="image-group">
                  <img :src="image" />

                  <a v-if="indexToRemove === index" href="javascript:void(0);" class="action-icon remove-image" v-b-modal.modal-remove-image @click="imageToRemove = indexToRemove" >
                    <i class="mdi mdi-trash-can font-size-14"></i>
                  </a>
                </div>

                <div class="new-image" v-else>
                  <!-- <i class="bx bx-image-add"></i>
                  <p>Clique para adicionar uma nova imagem</p> -->
                  <b-spinner class="m-2" variant="primary" role="status"></b-spinner>
                </div>
              </div>                  
              <input type="file" multiple accept="image/*" name="file" ref="file" v-on:change="handleFilesUpload" />
            </draggable>                
          </div>
        </div>

        <div class="row mt-4">
          <div class="col-sm-12">
            <button type="button" class="btn btn-primary btn-rounded mb-2 mr-2" @click="$refs.file.click()">
              <i class="mdi mdi-plus mr-1"></i> Adicionar Nova Foto
            </button>
          </div>
        </div>
      </div>
    </div>

    <b-modal id="modal-remove-image" title="Excluir Image" title-class="font-18" centered>
      <p>Confirma a exclusão da imagem do produto?</p>

      <div slot="modal-footer">
        <b-btn variant="link" @click="$bvModal.hide('modal-remove-image')">Cancelar</b-btn>
        <b-btn variant="primary" @click="removeImage">Excluir</b-btn>
      </div>
    </b-modal>
  </Layout>
</template>

<style lang="scss">
  .images {
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-wrap: wrap;

    .image {
      display: flex;
      flex-direction: row;
      align-items: center;

      .image-group {
        flex-direction: column;
        display: flex;
        position: relative;
      }
    }

    img {
      height: 100px;
      margin: 20px 20px 10px 20px;
      min-height: 80px;
    }

    .new-image {
      // border: 2px dashed #a6b0cf !important;
      height: 140px;
      width: 140px;
      border-radius: 6px;
      color: #a6b0cf;
      // font-size: 30px;
      justify-content: center;
      flex-direction: column;
      display: flex;
      align-items: center;
      // padding: 10px;

      p {
        font-size: 10px;
        text-align: center;
        margin: 6px 0px 0px 0px;
      }

      // margin: 20px;
    }

    input[type="file"] {
      display: none;
    }
  }

  .nav-link.disabled {
    color: #e1e1e1;
  }

  .remove-image {
    position: absolute;
    right: 14px;
    background-color: #fff;
    color: red;
    height: 10px;
    width: 10px;
    top: 14px;
    padding: 15px;
    margin: 0px;
    display: flex;
    border-radius: 50%;
    /* line-height: 10px; */
    justify-content: center;
    align-items: center;
  }
</style>